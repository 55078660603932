import React from "react"
import { Location } from "@reach/router"
import queryString from "query-string"
import JobsSearch from "../../templates/jobs-search"
const FilterRouteEs = () => {
  return (
    <Location>
      {({ location, navigate }) => (
        <JobsSearch
          location={location}
          navigate={navigate}
          search={location.search ? queryString.parse(location.search) : {}}
          lang={"es"}
        />
      )}
    </Location>
  )
}
export default FilterRouteEs
